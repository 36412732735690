.contact--1 {
	@media (max-width : 991px) {
		.offset--end_1 {
			padding-right: 0;
		}
		
		.h3 {
			font-size: $fs-h2;
		}
	}

	.mr--i_contact {
		max-width: 66%;

		@media (max-width : 991px) {
			margin: 0 auto;
			max-width: 41%;
		}

		@media (max-width : 575px) {
			max-width: 58%;
		}
	}
}

.contact--1_grid {
	// @media (min-width : 576px) {
	// 	display: grid;
	// 	grid-template-columns: 1fr 1fr;
	// 	grid-auto-rows: 1fr;
	// 	gap: 0 1.5rem;
	// }

	li {
		display: flex;
		align-items: center;
		padding: var(--lh-title) 1.5rem var(--lh-title) 0;
		border-top: var(--bw-base) solid $cl-white;

		@media (max-width : 991px) {
			padding: .8em 1rem;
			justify-content: center;
		}

		@media (max-width : 575px) {
			padding: 1.15em 2rem;
			justify-content: center;
		}

		&:last-child {
			border-bottom: var(--bw-base) solid $cl-white;
		}

		// &:nth-last-child(1) {
		// 	border-bottom: var(--bw-base) solid $cl-white;
		// }

		// &:nth-last-child(2) {
		// 	@media (min-width : 576px) {
		// 		border-bottom: var(--bw-base) solid $cl-white;
		// 	}
		// }
	}
}

.contact--form {
	@media (max-width : 991px) {
		.offset--end_1 {
			padding-right: 0;
		}
		
		.h3 {
			font-size: $fs-h2;
		}
	}
}