.footer-grid {
	display: grid;
	grid-template-columns: 1.15fr 0.85fr 1fr;
	grid-template-rows: auto 1fr auto auto auto;
	grid-template-areas:
		"footer_text footer_nav footer_address"
		"footer_lang footer_nav footer_address"
		"footer_lang footer_nav footer_social"
		"footer_divisor_1 . footer_divisor_2"
		"footer_brand footer_copyright footer_privacy";
	gap: 0 var(--gutter-container);

	@media (max-width : 1399px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	
	@media (max-width : 991px) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto auto 1fr auto auto auto;
		grid-template-areas:
			"footer_text footer_address"
			"footer_divisor_1 ."
			"footer_social footer_nav"
			"footer_lang footer_nav"
			"footer_divisor_2 ."
			"footer_brand footer_privacy"
			"footer_brand footer_copyright"
		;
		gap: 0 0;
	}
	
	@media (max-width : 575px) {
		grid-template-columns: 1fr auto;
		grid-template-rows: repeat(6, auto);
		grid-template-areas:
			"footer_text footer_text"
			"footer_nav footer_social"
			"footer_address footer_address"
			"footer_lang ."
			"footer_brand footer_brand"
			"footer_bottom footer_bottom"
		;
		gap: var(--gutter-y-lg) 0;
	}
}

.footer--text {
	grid-area: footer_text;

	@media  (max-width : 575px) {
		font-size: $fs-lead;
	}
}

.footer--lang {
	grid-area: footer_lang;

	@media (max-width : 1399px) {
		font-size: var(--fs-medium);
	}

	@media (max-width : 767px) {
		font-size: 1rem;
	}

	@media (max-width : 575px) {
		font-size: 1rem;
	}

	.dropdown {
		border-radius: var(--br-base);
		border: var(--bw-base) solid $cl-sand;

		@media (hover : hover) {
			&:hover {
				button {
					.icon {
						transform: rotate(180deg)
					}
				}

				.dropdown-menu {
					height: calc(2.32em * (var(--languages-count) - 1));
				}
			}
		}
	}
	
	button {
		&.show {
			.icon {
				transform: rotate(180deg)
			}
		}
	}	

	.dropdown-menu {
		height: 0;
		overflow: hidden;
		transition: height var(--time-slow) ease;

		&.show {
			height: calc(2.32em * (var(--languages-count) - 1));
		}
	}
}

.footer--lang_item {
	display: flex;
	align-items: center;
	line-height: 1;
	padding: .66em 1.15em;
	width: 100%;

	img {
		display: block;
		margin-right: .5em;
		width: 1em;
		flex: 0 0 1em;
	}

	.icon {
		flex-shrink: 0;
		margin-left: 2rem;
		font-size: 80%;
		transition: transform var(--time-slow) ease;

		@media (max-width : 767px) {
			margin-left: 1rem;
		}
	}

	@media (hover : hover) {
		&:not([data-bs-toggle]):hover {
			opacity: .5;
		}
	}
}

.footer--nav {
	grid-area: footer_nav;
	font-size: $fs-h3;

	li + li {
		margin-top: calc(var(--lh-content) / 6);

		@media (max-width : 991px) {
			margin-top: calc(var(--lh-content) / 4);
		}
	}
}

.footer--address {
	grid-area: footer_address;

	br:last-child {
		display: none;
	}
}

.footer--social {
	grid-area: footer_social;
	font-size: calc(#{$fs-h3} * 0.8);
	padding: .25em 0;

	@media (max-width : 991px) {
		padding-bottom: $fs-h2;
	}

	@media (max-width : 575px) {
		padding-bottom: 0;
	}

	li + li {
		margin-left: 1em;

		@media (max-width : 767px) {
			margin-left: .75em;
		}
		
		@media (max-width : 575px) {
			margin: .6em 0 0;
		}
	}
	
	a {
		display: block;
		padding: .35em;
		border-radius: 50%;
		border: var(--bw-base) solid $cl-sand;

		@media (hover : hover) {
			&:hover {
				background: $cl-sand;
				color: $cl-sand-deep;
			}
		}
	}
}

.footer--brand {
	grid-area: footer_brand;

	a {
		max-width: 58%;

		@media (max-width : 1399px) {
			max-width: 66%;
		}

		@media (max-width : 767px) {
			max-width: 72%;
		}
		
		@media (max-width : 575px) {
			max-width: 50%;
		}
	}
}

.footer--copyright {
	grid-area: footer_copyright;

	span {
		display: block;
		margin-top: .25em;

		a {
			text-decoration: none;
		}
	}
}

.footer--privacy {
	grid-area: footer_privacy;
}

.footer--bottom {
	grid-area: footer_bottom;

	a {
		text-decoration: none;
	}
}

.footer--divisor_1 {
	grid-area: footer_divisor_1;
}

.footer--divisor_2 {
	grid-area: footer_divisor_2;
}

.footer--lgpd {
	padding: calc(var(--gutter-y-sm) / 1.5) 0;
	transform: translate3d(0,101%,0);
	transition: transform var(--time-slow) ease;

	@media (max-width : 575px) {
		padding: var(--lh-content) 0;
	}

	&.is--not-acceped {
		transform: translate3d(0,0,0);
	}

	p {
		@media (min-width : 576px) {
			padding-right: calc(var(--gutter-container) * 1.5);
		}

		@media (min-width : 1660px) {
			padding-right: calc(var(--gutter-container) * 2.5);
		}
	}
}