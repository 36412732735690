.env {
	main {
		@media (max-width : 991px) {
			.offset--end_1 {
				padding-right: 0 !important;
			}

			.offset--start_1 {
				padding-left: 0 !important;
			}
		}
	}
}

.env--wide {
	.mr--i:before {
		@media (max-width : 991px) {
			padding-bottom: 56.3%;
		}
	}
}

.env--circles {
	@media (max-width : 1199px) {
		padding: 0 !important;
	}

	.mr--i {
		overflow: visible;

		&:after {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
			border: var(--bw-base) solid $cl-sand;
			border-radius: 50%;
			transition: all var(--time-slow) ease;
		}
	}

	.mr--i_center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%,-50%,0);
	}

	.icon {
		margin: 0 auto 1rem;
		font-size: var(--fs-h1);

		@media (max-width : 575px) {
			margin-bottom: .5rem;
		}
	}

	.row {
		margin: 0 -6px;
	}

	.col-sm {
		padding: 0 6px;
		
		@media (hover : hover) and (min-width : 1200px) {
			transition: all var(--time-slow) ease;

			&:hover {
				flex: 0 0 50%;
				width: 50%;
				padding: 0 6vw;

				.mr--i {
					&:after {
						transform: rotate(-45deg);
						border-radius: 8%;
					}
				}
			}
		}
	}
}

.env--circles_area {
	@media (min-width : 1200px) {
		position: relative;
		padding-bottom: 50%;

		> div {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			transform: translate3d(0,-50%,0);
		}
	}
}

.env--3 {
	li {
		padding: var(--lh-content) 0;
		border-top: var(--bw-base) solid $cl-sand;

		@media (max-width : 575px) {
			padding: var(--lh-title) 0;
		}

		&:last-child {
			border-bottom: var(--bw-base) solid $cl-sand;
		}
	}
}

.env--4 {
	@media (max-width : 575px) {
		padding-bottom: 0;
	}

	.mr--i:before {
		@media (max-width : 991px) {
			padding-bottom: 66%;
		}
	}

	.col-lg.parallax {
		z-index: 3;
	}

	.leaf {
		position: absolute !important;
		z-index: 2 !important;

		img {
			width: 100%;
		}
	}

	.leaf--1 {
		position: absolute !important;
		top: -16% !important;
		left: 6% !important;
		width: 16%;
		z-index: 2;
		
		@media (max-width : 991px) {
			left: auto !important;
			right: -5%;
		}
		
		img {
			transform: rotate(28deg) !important;
			filter: blur(10px);

			@media (max-width : 991px) {
				filter: blur(5px);
			}
		}
	}

	.leaf--2 {
		position: absolute !important;
		top: 100% !important;
		right: 0;
		left: auto !important;
		width: 11%;
		z-index: 2;
		
		img {
			transform: rotate(-28deg) !important;
	
			@media (max-width : 991px) {
				transform: rotate(-28deg) translateY(-50%) !important;
			}
		}
	}

	.leaf--3 {
		top: 100% !important;
		left: 0 !important;
		width: calc(var(--gutter-container) * 1.25);
		z-index: 2;
	
		@media (max-width : 991px) {
			left: 16% !important;
			width: calc(var(--gutter-container) * 1.5);
		}
		
		img {
			transform: rotate(-220deg) translate(20%, -40%) !important;
			transform-origin: top center;
			filter: blur(10px);
			
			@media (max-width : 991px) {
				filter: blur(5px);
			}
		}
	}

	.leaf--4 {
		top: auto !important;
		bottom: 100%;
		left: 50% !important;
		width: 12%;
		z-index: 2;
		
		img {
			transform: rotate(28deg) translate(0,66%) !important;
			filter: blur(10px);
			
			@media (max-width : 991px) {
				filter: blur(5px);
			}
		}
	}
}

.env--5 {
	// @media (max-width : 991px) {
	// 	padding-bottom: 0;
	// }

	.leaf {
		position: absolute !important;
		top: 5% !important;
		left: 84% !important;
		max-width: 20%;
		
		img {
			transform: rotate(41deg) !important;
		}
	}
}

.env--contact {
	.mr--i_full.is--revealed {
		opacity: .15 !important;
	}
}