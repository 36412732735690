@keyframes animateLoading {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes animateIntroAnchor {
	from {
		height: 33%;
	}

	to {
		height: 43%;
	}
}