.btn--i {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: space-evenly;
	padding: .87em 2em 1.03em;
	font-size: 1rem;
	line-height: 1;
	background: $cl-sand-deep;
	border-radius: var(--br-base);
	color: $cl-sand;
	user-select: none;
	white-space: nowrap;
	text-decoration: none;
	text-align: center;

	@media (max-width : 575px) {
		min-width: 84%;
		padding: .97em 1.75em 1.13em;
	}

	@media (max-width : 375px) {
		min-width: 100%;
	}

	@media (hover : hover) {
		&:not(:disabled):hover {
			color: $cl-sand;
			background: $cl-sand-deep-hover;
		}
	}

	&:active {
		transform: scale(.9);
	}

	&:before {
		content: attr(data-sending);
		display: block;
		pointer-events: none;
		position: absolute;
		inset: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: rgba(255,255,255,0);
		transition: color var(--time-normal) ease;
	}

	&[type="submit"]:disabled {
		transform: none !important;
		cursor: wait;
		background: $cl-sand-deep-hover !important;
		color: rgba(255,255,255,0) !important;

		&:before {
			color: $cl-sand !important;
		}
	}
}

.btn--i_green {
	background: $cl-green;
	
	@media (hover : hover) {
		&:not(:disabled):hover {
			background: $cl-green-hover;
		}
	}
}

.btn--i_wider {
	padding: .87em 3em 1.03em;

	@media (max-width : 575px) {
		padding: .97em 2.5em 1.13em;
	}
}