/*
 * bootstrap
*/
	@import '~bootstrap5/scss/functions';
	@import '~bootstrap5/scss/variables';
	@import '~bootstrap5/scss/mixins';
	@import '~bootstrap5/scss/utilities';

	@import '~bootstrap5/scss/root';
	@import '~bootstrap5/scss/reboot';
	@import '~bootstrap5/scss/grid';
	@import '~bootstrap5/scss/helpers';
	@import '~bootstrap5/scss/containers';
	@import '~bootstrap5/scss/type';
	@import '~bootstrap5/scss/images';
	@import '~bootstrap5/scss/transitions';
	@import '~bootstrap5/scss/forms';
	@import '~bootstrap5/scss/utilities/api';


/*
 * fancybox
*/
	// @import "@fancyapps/ui/dist/fancybox.css";

/*
 * swiper
*/
	$themeColor: #4F5A34 !default;
	@import '~swiper/swiper';
	@import '~swiper/components/lazy/lazy';

/*
 * site
*/
	@import 'site/defaults';
	@import 'site/keyframes';
	@import 'site/form';
	@import 'site/elements';

	@import 'site/components/media';
	@import 'site/components/btn';
	@import 'site/components/reveal';

	@import 'site/header';
	@import 'site/footer';
	@import 'site/pages';

	@import 'site/pages/index';
	@import 'site/pages/about';
	@import 'site/pages/env';
	@import 'site/pages/process';
	@import 'site/pages/contact';
	@import 'site/pages/work';
	@import 'site/pages/privacy';
	