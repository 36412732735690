.icon {
	display: block;
	width: 1em;
	height: 1em;
}

.icon--90 {
	transform: rotate(90deg);
}

.icon---90 {
	transform: rotate(-90deg);
}

.icon--loading {
	animation: animateLoading var(--time-smooth) ease 0s infinite;
}

.alert {
	position: fixed;
	padding: $lh-content var(--gutter-container);
	text-align: center;
	top: $fs-h3;
	left: 50%;
	right: var(--gutter-container);
	width: 50vw;
	z-index: 19;
	color: $cl-alert-contrast;
	font-size: 1rem;
	line-height: $lh-content;
	font-weight: normal;
	transform: translate3d(-50%,-150%,0);
	transition: transform var(--time-slow) ease;
	cursor: pointer;
	user-select: none;
	border-radius: var(--br-base);

	@media (max-width : 767px) {
		width: 66%;
	}

	@media (max-width : 575px) {
		top: var(--gutter-container);
		width: calc(100% - (var(--gutter-container) * 2));
	}

	&.is--visible {
		transform: translate3d(-50%,0,0);
	}
}

.alert--success {
	background: $cl-success;
}

.alert--error {
	background: $cl-error;
}