.form-floating {
	position: relative;
	margin-bottom: 2rem;

	> label {
		opacity: 1 !important;
		padding: $fs-small 0;
		color: $cl-sand-deep;
		line-height: var(--lh-content);
		transition: font var(--time-normal) ease, transform var(--time-normal) ease, color var(--time-normal) ease;
		user-select: none;
	}

	> .form-control {
		height: calc((#{$fs-small} * 2) + (1rem * var(--lh-content)));
		padding: $fs-small 0 !important;
		font-size: 1rem;
		line-height: var(--lh-content);
		background: none !important;
		color: $cl-base !important;
		font-family: $ff-base;
		border: none !important;
		border-bottom: var(--bw-base) solid $cl-sand !important;
		appearance: none;
		border-radius: 0;
		box-shadow: none !important;
		transition: color var(--time-normal) ease, border var(--time-normal) ease, background 5000s ease;
		resize: none;

		&:focus {
			border-bottom-color: $cl-sand-deep !important;
		}
	}

	> .icon {
		color: $cl-sand-deep;
		position: absolute;
		top: 50%;
		right: 0;
		width: $fs-small;
		height: $fs-small;
		transform: translateY(-50%);
		pointer-events: none;
		transition: color var(--time-normal) ease;
	}

	> .form-select:not(.form-select-selected) ~ label {
		transform: none !important;
	}

	> .form-control:not(.form-select):focus,
	> .form-control:not(.form-select):not(:placeholder-shown),
	> .form-select.form-select-selected {
		& ~ label {
			transform: translateY(calc(0px - (#{$fs-small} * 2)));
			font-size: $fs-small;
		}
	}
}

.form-file {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 1.5em 1.8em;
	transition: background var(--time-normal) ease;
	text-align: left;
	margin-bottom: 2rem;

	@media (max-width : 575px) {
		padding: 1.5rem var(--gutter-container);
		margin-left: calc(0px - var(--gutter-container));
		margin-right: calc(0px - var(--gutter-container));
	}

	@media (hover : hover) {
		&:hover {
			.icon {
				&:nth-last-child(2) {
					transform: translateY(-10%);
				}
			}
		}
	}

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		inset: 0;
		border: var(--bw-base) dashed $cl-sand;
	}

	&.is--drag {
		background: none !important;

		.icon {
			&:nth-last-child(2) {
				transform: translateY(-10%);
			}
		}
	}

	&.has--file {
		.icon {
			&:nth-last-child(1) {
				opacity: 1 !important;
			}
			
			&:nth-last-child(2) {
				opacity: 0;
			}
		}

		.form-file-label {
			word-break: break-all;
		}
	}

	.mr--i {
		font-size: $fs-h1;
		flex: 0 0 1.1em;
		margin: 0 1.8rem 0 3.9rem;

		@media (max-width : 575px) {
			flex: 0 0 1.4em;
			margin: 0 1rem 0 2rem;
		}

		.icon {
			width: 100%;
			transition: all var(--time-normal) ease;

			&:nth-last-child(1) {
				opacity: 0;
			}
		}
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 19;
		cursor: pointer;
	}
}

.form-checkbox {
	position: relative;
	display: block;
	margin-bottom: 2rem;
	text-align: left;
	
	> span {
		display: flex;
		align-items: center;
		padding: $fs-small 0;
		font-size: $fs-small;
		line-height: var(--lh-title);
		position: relative;
		border-bottom: var(--bw-base) solid $cl-sand;
		color: $cl-sand-deep;
		cursor: pointer;
		user-select: none;
		transition: color var(--time-normal) ease, border var(--time-normal) ease;

		> span {
			flex: 1 1 auto;
		}
		
		> small {
			position: relative;
			flex: 0 0 1rem;
			height: 1rem;
			border: var(--bw-base) solid $cl-sand;
			margin-right: 1rem;
			border-radius: 20%;
			transition: background var(--time-normal) ease, border var(--time-normal) ease;

			.icon {
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				fill: $cl-white;
				stroke: $white;
				stroke-width: 1;
			}
		}
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;

		&:focus-visible ~ span {
			color: $cl-green;
			border-bottom-color: $cl-sand-deep;
		}

		&:checked ~ span {
			color: $cl-green;

			> small {
				border-color: $cl-green;
				background: $cl-green;
			}
		}
	}

	@media (hover : hover) {
		&:hover {
			> span {
				> small {
					border-color: $cl-sand-deep
				}
			}
		}
	}
}

select::-ms-expand { display: none; }

:-webkit-autofill,
:-webkit-autofill:hover, 
:-webkit-autofill:focus {
	-webkit-text-fill-color: $cl-base;
	box-shadow: 0 0 0px 1000px $cl-white inset;
	transition: color var(--time-normal) ease, border var(--time-normal) ease, background 5000s ease;
}

.is--validated {
	.form-floating {
		> .form-control:invalid {
			color: $cl-error !important;
			border-color: rgba(var(--cl-error-rgb), .2) !important;

			&:focus {
				border-color: $cl-error !important;
			}

			& ~ .icon,
			& ~ label {
				color: $cl-error !important;
			}
		}
	}

	.form-checkbox {
		input:invalid ~ span {
			color: $cl-error !important;
			border-color: rgba(var(--cl-error-rgb), .2) !important;

			> small {
				border-color: rgba(var(--cl-error-rgb), .2) !important;
			}
		}

		input:focus:invalid ~ span {
			border-color: $cl-error !important;
		}
	}
}