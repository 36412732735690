.work {
	.sc {
		@media (max-width : 991px) {
			.offset--start_1 {
				padding-left: 0;
			}
			
			.offset--end_1 {
				padding-right: 0 !important;
			}
		}
	}
}

.work--grid {
	.container--1 {
		position: relative;
		z-index: 2;
	}
}

.work--grid_base {
	margin: 0 -0.5rem -1rem;

	@media (max-width : 575px) {
		margin: 0 calc(0px - (var(--gutter-container) - 0.25rem)) -1rem;
	}

	img {
		display: block;
		width: calc(100% - 1rem);
		margin: 0 auto;

		@media (max-width : 575px) {
			width: calc(100% - 0.5rem);
		}
	}
}

.work--grid_item {
	width: 20%;
	margin-bottom: 1rem;

	// @media (max-width : 991px) {
	// 	width: 20%;
	// }

	@media (max-width : 767px) {
		width: 33.333%;
	}

	@media (max-width : 575px) {
		margin-bottom: .5rem;
	}

	&:nth-child(2) {
		margin-top: 7rem;
		
		@media (max-width : 767px) {
			margin-top: 4rem;
		}
	}

	&:nth-child(3) {
		margin-top: 5rem;

		@media (max-width : 767px) {
			margin-top: 2rem;
		}
	}

	&:nth-child(4) {
		@media (min-width : 768px){
			margin-top: 3rem;
		}
	}

	&:nth-child(5) {
		@media (min-width : 768px){
			margin-top: 5rem;
		}
	}

	&:nth-child(6) {
		@media (min-width : 1200px) {
			margin-top: 2rem;
		}
	}
}

.work--1,
.work--2 {
	@media (max-width : 575px) {
		padding-bottom: 0;
	}

	.mr--i {
		&:before {
			@media (max-width : 991px) {
				padding-bottom: 66%;
			}
		}
	}
}

.work--values {
	.h3 {
		@media (max-width : 991px) {
			font-size: $fs-h2;
		}
	}
}

.work--values_item {
	border-top: var(--bw-base) solid $cl-sand;

	&:last-child {
		border-bottom: var(--bw-base) solid $cl-sand;
	}

	button {
		font-family: $ff-base;
		font-size: 1rem;
		color: $cl-sand-deep;
		padding: var(--lh-content) 0;
		cursor: zoom-in;

		.icon {
			margin-left: 2em;
			transition: transform var(--time-normal) ease;
		}

		&.is--active {
			.icon {
				transform: rotate(180deg);
			}
		}
	}

	.collapsing {
		transition: height var(--time-normal) ease;
	}

	.text--area {
		padding: 0 0 calc(var(--lh-content) * 1.5);
	}
}

.work--benefits {
	.h3 {
		margin-bottom: calc(var(--lh-title) * 0.8);
		
		@media (max-width : 991px) {
			font-size: $fs-h2;
		}
	}

	.col-6 {
		&:nth-last-child(1),
		&:nth-last-child(2) {
			.work--benefits_item {
				border-bottom: var(--bw-base) solid $cl-white;
			}
		}
	}
}

.work--benefits_item {
	padding: .9rem 0;
	border-top: var(--bw-base) solid $cl-white;
	min-height: 100%;

	@media (max-width : 575px) {
		padding: var(--lh-title) 0;
	}

	p {
		@media (max-width : 575px) {
			line-height: var(--lh-title);
		}
	}

	img {
		display: block;
		flex: 0 0 var(--lh-content);
		width: var(--lh-content);
		height: calc(var(--lh-content) * 1.5);
		object-position: center;
		object-fit: contain;
		margin-right: 1em;

		@media (max-width : 575px) {
			width: calc(var(--lh-content) * 1.25);
			height: calc(var(--lh-content) * 1.75);
			flex: 0 0 calc(var(--lh-content) * 1.75);
			margin: 0 0 .25rem;
		}
	}
}

.work--benefits_item_border {
	border-color: $cl-sand !important;
}

.work--jobs {
	.row {
		margin-bottom: calc(0px - var(--bs-gutter-x));
		
		> div {
			padding-bottom: var(--bs-gutter-x);
		}
	}

	a {
		display: flex;
		flex-direction: column;
		border-radius: var(--br-base);
		background: $cl-sand;
		padding: var(--lh-title) var(--lh-content);
		// aspect-ratio: 2 / 1;
		min-height: 100%;

		strong {
			font-weight: 400;
		}

		span {
			flex: 1 1 auto;
			padding-bottom: calc(var(--lh-content) * 2);

			@media (max-width : 767px) {
				padding-bottom: calc(var(--lh-content) / 2);
			}
		}

		.icon {
			align-self: flex-end;
			transform: rotate(-90deg);
			transition: transform var(--time-normal) ease;
		}

		@media (hover : hover) {
			&:hover {
				.icon {
					transform: rotate(-90deg) translateY(25%);
				}
			}
		}
	}
}

.work--form {
	.h3 {
		margin-bottom: calc(var(--lh-title) * 0.8);

		@media (max-width : 991px) {
			font-size: $fs-h2;
		}
	}
}

.work--title {
	&:not(:nth-last-child(2)) {
		@media (min-width : 992px) {
			padding-bottom: 0;
		}
	}

	& + .container--1 {
		@media (min-width : 992px) {
			display: none;
		}
	}

	a {
		padding: .5rem 0;
		color: $cl-sand-deep;

		@media (max-width : 991px) {
			margin-top: -1rem;
			padding: 1rem 0;
		}

		.icon {
			transform: rotate(90deg) translateX(6%);
			margin-right: 1rem;
			transition: transform var(--time-normal) ease;
		}

		@media (hover : hover) {
			&:hover {
				.icon {
					transform: rotate(90deg) translateY(25%) translateX(6%);
				}
			}
		}
	}
}

.work--info {
	.h3 {
		margin-bottom: calc(var(--lh-title) * 0.8);
		
		@media (max-width : 991px) {
			font-size: $fs-h2;
		}
	}
}