.index--banner_controls {
	display: flex;
	font-size: $fs-lead;
	width: var(--gutter-container);
	height: var(--gutter-container);
	cursor: pointer;
	z-index: 19;
	transition: color var(--time-normal) ease;

	@media (max-width : 991px) {
		display: none !important;
	}

	@media (hover : hover) {
		&:hover {
			color: $cl-sand-deep;
		}
	}
}

.index--banner_pager {
	display: flex;
	padding-right: var(--gutter-container);
	height: calc(var(--offset-header) * 1.25);
	z-index: 19;
	pointer-events: none;

	@media (min-width : 992px) {
		display: none !important;
	}

	span {
		display: block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: $cl-sand;
		margin-left: 10px;
		pointer-events: all;
		transition: background var(--time-normal) ease;

		@media (max-width : 575px) {
			width: 8px;
			height: 8px;
			margin-left: 8px;
		}

		&.is--active {
			background: $cl-sand-deep;
		}

		@media (hover : hover) {
			&:hover {
				background: $cl-sand-deep;
			}
		}
	}
}

.index--group {
	@media (max-width : 575px) {
		padding-bottom: 0;
	}

	.mr--i {
		&:before {
			@media (max-width : 1199px) {
				padding-bottom: 100%;
			}
			
			@media (max-width : 991px) {
				padding-bottom: 66%;
			}
		}
	}

	.offset--start_1 {
		@media (max-width : 991px) {
			padding-left: 0 !important;
		}
	}
}

.index--process {
	@media (max-width : 575px) {
		padding-top: calc(56.3% + var(--gutter-y-lg));
	}
	
	.mr--i {
		@media (max-width : 575px) {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}

	ul {
		li {
			font-family: $ff-title;;
			font-size: $fs-h3;
			line-height: var(--lh-title);

			& + li {
				margin-top: var(--lh-title);

				@media (max-width : 1199px) {
					margin-top: .75em;
				}
			}
		}

		small {
			font-size: 1rem;
			margin-right: 1em;
		}
	}

	ul,
	.btn--i {
		@media (max-width : 991px) and (min-width : 768px) {
			flex: 1 1 auto;
		}
	}
	
	.offset--end_1 {
		@media (max-width : 991px) {
			padding-right: 0 !important;
		}
	}
}

.index--contact {
	img.sr.is--revealed {
		opacity: .4 !important;
	}
}