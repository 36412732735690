.sc {
	padding-top: var(--gutter-y-lg);
	padding-bottom: var(--gutter-y-lg);
}

.sc--2x {
	padding-top: var(--gutter-y-xl);
	padding-bottom: var(--gutter-y-xl);
}

.sc--3x {
	padding-top: var(--gutter-y-2xl);
	padding-bottom: var(--gutter-y-2xl);
}

.sc--intro {
	position: relative;
	height: clamp(500px, 100vh, 1080px);

	@media (min-width : 1024px) and (min-height : 900px) and (orientation : portrait) {
		height: clamp(500px, 100vh, 50vh);
	}
}

.sc--intro_single {
	padding-top: calc(var(--offset-header) * 2);
	padding-bottom: calc(var(--offset-header) * 2);
	height: auto;
	min-height: 100vh;

	@media (max-width : 575px) {
		padding-top: calc(var(--offset-header) * 1.5);
		padding-bottom: calc(var(--offset-header) * 1.5);
	}

	h1:last-child {
		margin-bottom: 0;
	}
}

.sc--intro_title {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate3d(0,-50%,0);
	z-index: 3;
	user-select: none;
}

.sc--intro_media {
	object-fit: cover;
	object-position: center center;
	z-index: 2;

	&[alt] {
		z-index: 1;
	}
}

.sc--intro_link {
	z-index: 4;
}

.sc--intro_anchor {
	z-index: 4;
	height: calc(var(--offset-header) * 1.25);

	@media (max-width : 575px) {
		height: var(--offset-header);
	}

	button {
		z-index: 2;
		width: calc(var(--offset-header) * 1.25);
		height: calc(var(--offset-header) * 1.25);

		@media (max-width : 575px) {
			width: var(--offset-header);
			height: var(--offset-header);
		}

		span {
			top: 33%;
			width: 2px;
			height: 33%;
			// height: 41%;
			background: currentColor;
			animation: animateIntroAnchor var(--time-smooth) ease-in-out 0s infinite alternate;

			@media (max-width : 575px) {
				animation: animateIntroAnchor var(--time-slow) ease-in-out 0s infinite alternate;
			}

			&:before {
				content: '';
				position: absolute;
				right: 1px;
				bottom: -1px;
				display: block;
				width: 1rem;
				height: 1rem;
				border-right: 2px solid currentColor;
				border-bottom: 2px solid currentColor;
				transform-origin: bottom right;
				transform: rotate(45deg);
			}
		}
	}
}