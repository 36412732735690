.mr--i {
	display: block;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	z-index: 1;

	&:before {
		content: '';
		display: block;
	}

	&.mr--i_16by9:before { padding-bottom: 56.3%; }
	&.mr--i_8by6:before { padding-bottom: 82%; }
	&.mr--i_4by3:before { padding-bottom: 75%; }
	&.mr--i_3by2:before { padding-bottom: 66%; }
	&.mr--i_1by1:before { padding-bottom: 100%; }
	&.mr--i_2by1:before { padding-bottom: 50%; }
	&.mr--i_wide:before { padding-bottom: 40%; }
	&.mr--i_brand:before { padding-bottom: 28%; }
	&.mr--i_contact:before { padding-bottom: 28%; }
}

.mr--i_fit {
	position: absolute;
	z-index: 1;
	max-height: 100%;
	max-width: 100%;
	width: auto;
	height: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.mr--i_full {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&[alt] {
		object-fit: cover;
		object-position: center center;
	}
}

.mr--i_content {
	position: relative;
	z-index: 2;
}

.mr--i_rounded {
	border-radius: var(--br-base);
}

.mr--i_edgeless {
	@media (max-width : 575px) {
		margin-left: calc(0px - var(--gutter-container));
		margin-right: calc(0px - var(--gutter-container));
		border-radius: 0;
	}
}

.mr--i_video {
	background: black;
	
	a {
		color: white !important;
		transition: opacity var(--time-normal) ease;

		img {
			opacity: 1;
			transition: opacity var(--time-normal) ease;
		}

		@media (hover : hover) {
			&:hover {
				img {
					opacity: .66;
				}
			}
		}
		
		&.is--playing {
			opacity: 0;
		}

		.icon {
			filter: drop-shadow(.15rem .15rem .1rem rgba(0,0,0,0.25));
			z-index: 2;
		}
	}
}