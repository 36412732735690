//!* Vars and mixins
	$cl-base: var(--cl-base);
	$cl-contrast: var(--cl-contrast);

	$cl-green: var(--cl-green);
	$cl-green-hover: var(--cl-green-hover);
	$cl-green-deep: var(--cl-green-deep);
	$cl-sand: var(--cl-sand);
	$cl-sand-deep: var(--cl-sand-deep);
	$cl-sand-deep-hover: var(--cl-sand-deep-hover);
	$cl-orange: var(--cl-orange);
	$cl-blue: var(--cl-blue);
	$cl-white: var(--cl-white);

	$cl-success: var(--cl-success);
	$cl-error: var(--cl-error);
	$cl-alert-contrast: var(--cl-alert-contrast);

	$ff-base: var(--ff-base);
	$ff-title: var(--ff-title);
	
	$fs-h1: var(--fs-h1);
	$fs-h2: var(--fs-h2);
	$fs-h3: var(--fs-h3);

	$fs-base: 1rem;
	$fs-small: var(--fs-small);
	$fs-medium: var(--fs-medium);
	$fs-lead: var(--fs-lead);

	$lh-content: var(--lh-content);

//!* Resets - defaults
	:root {
		font-size: 17px;
		@media (min-width : 1921px) { font-size: 25px; }
		@media (max-width : 1920px) and (min-width : 1200px) { font-size: calc(18px + (25 - 18) * ((100vw - 1200px) / (1920 - 1200))); }
		@media (max-width : 1199px) and (min-width : 992px) { font-size: calc(17px + (18 - 17) * ((100vw - 992px) / (1199 - 992))); }
		@media (max-width : 991px) and (min-width : 768px) { font-size: 19px; }
		@media (max-width : 767px) and (min-width : 411px) { font-size: 18px; }

		@media (min-width : 1921px) {
			--offset-header: calc(var(--gutter-y-sm) + 84px);
		}

		@media (max-width : 1920px) {
			--offset-header: calc(var(--gutter-y-sm) + 4.5vw);
		}

		@media (max-width : 1199px) {
			--offset-header: calc(var(--gutter-y-sm) + 5.5vw);
		}

		@media (max-width : 991px) {
			--offset-header: calc(var(--gutter-y-sm) + 60px);
		}

		@media (max-width : 767px) {
			--offset-header: calc(var(--gutter-y-sm) + 53px);
		}

		@media (max-width : 575px) {
			--offset-header: calc(var(--gutter-y-sm) + 45px);
		}

		--fs-h1: 3.6rem;
		--fs-h2: 2.4rem;
		--fs-h3: 1.6rem;

		--fs-small: 0.8rem;
		--fs-medium: 0.9rem;
		--fs-lead: 1.2rem;

		@media (max-width : 767px) {
			--fs-h1: 3.2rem;
			--fs-h2: 2rem;
			--fs-h3: 1.45rem;
		}

		@media (max-width : 575px) {
			--fs-h1: 2.3rem;
			--fs-h2: 1.8rem;
			--fs-h3: 1.3rem;
		}

		@media (max-width : 375px) {
			--fs-h1: 2.1rem;
			--fs-h2: 1.7rem;
			--fs-h3: 1.3rem;
		}

		--ff-base: 'Atyp Text', system-ui, sans-serif;
		--ff-title: 'Atyp Display', system-ui, sans-serif;

		--cl-base: #4F5A34;
		--cl-contrast: #F4ECE5;

		--cl-green: #4F5A34;
		--cl-green-hover: #434d2a;
		--cl-green-deep: #182117;
		--cl-sand: #F4ECE5;
		--cl-sand-deep: #9a8056;
		--cl-sand-deep-hover: #7C6745;
		--cl-orange: #A34123;
		--cl-blue: #5e79af;
		--cl-white: #fff;
		
		--cl-error: #BF0F30;
		--cl-error-rgb: 191, 15, 48; 
		--cl-success: #4F5A34;
		--cl-alert-contrast: #F4ECE5;

		--lh-content: 1.48em;
		--lh-title: 1.11em;

		--br-base: 0.8rem;

		--bw-base: 2px;

		@media (max-width : 991px) {
			--bw-base: 1px;
		}

		--time-smooth: 1200ms;
		--time-slow: 750ms;
		--time-normal: 500ms;
		--time-fase: 300ms;
		--time-flash: 100ms;
		--time-delay: 250ms;

		--gutter-container: 7vw;
		--gutter-y-sm: 1.4rem;
		--gutter-y-lg: 7vw;
		--gutter-y-xl: 10vw;
		--gutter-y-2xl: 13vw;

		@media (max-width : 1199px) {
			--gutter-container: 5vw;
		}

		@media (max-width : 991px) {
			--gutter-container: 6vw;
			--gutter-y-lg: 8vw;
			--gutter-y-xl: 11vw;
			--gutter-y-2xl: 14vw;
		}

		@media (max-width : 767px) {
			--gutter-y-lg: 12vw;
			--gutter-y-xl: 16vw;
			--gutter-y-2xl: 18vw;
		}
		
		@media (max-width : 575px) {
			--gutter-container: 5vw;
			--gutter-y-sm: 1rem;
		}
		
		@media (max-width : 410px) {
			--gutter-container: 6vw;
			--gutter-y-lg: 14vw;
			--gutter-y-xl: 17vw;
			--gutter-y-2xl: 20vw;
		}
	}

	::selection {
		color: $cl-contrast;
		background: $cl-base;
	}

	*, *:before, *:after {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		font-feature-settings: 'liga';
	}

	html { background: $cl-contrast; }

	body {
		background: $cl-contrast;
		color: $cl-base;
		font-family: $ff-base;
		font-weight: 400;
	}

	hr {
		border-top: var(--bw-base) solid $cl-white;
	}

	.hr--sand {
		border-top-color: $cl-sand;
	}

	.text--bold,
	b,
	strong {
		font-weight: bold;
	}

	p,
	ul,
	ol {
		margin-bottom: $lh-content;
		line-height: $lh-content;
	}

	p {
		a {
			text-decoration: underline;
		}
	}

	.text--lead {
		font-size: $fs-lead;
	}
	
	.text--small {
		font-size: $fs-small;
	}

	img {
		user-select: none;
	}

	button {
		font-size: $ff-base;
		background: none;
		padding: 0;
		color: currentColor;
		border: none;
		outline: none !important;
		transition: color var(--time-normal) ease, background var(--time-normal) ease, border var(--time-normal) ease, transform var(--time-flash), box-shadow var(--time-normal) ease, opacity var(--time-normal) ease;
	}

	a {
		transition: color var(--time-normal) ease, background var(--time-normal) ease, border var(--time-normal) ease, transform var(--time-flash), box-shadow var(--time-normal) ease, opacity var(--time-normal) ease;
		color: currentColor;
		text-decoration: none;

		&:hover {
			color: inherit;
		}
	}

	.link--green-deep {
		color: $cl-green-deep;

		@media (hover : hover) {
			&:hover {
				color: $cl-sand-deep;
			}
		}
	}

	.link--sand-deep {
		color: $cl-sand-deep;

		@media (hover : hover) {
			&:hover {
				color: $cl-sand;
			}
		}
	}

	.link--sand {
		color: $cl-sand;

		@media (hover : hover) {
			&:hover {
				color: $cl-sand-deep;
			}
		}
	}

	svg {
		display: block;
		fill: currentColor;
		user-select: none;
	}

	.font--title {
		font-family: $ff-title;
		font-weight: 400;
	}

	h1, .h1, h2, .h2, h3, .h3 {
		font-family: $ff-title;
		font-weight: 400;
		margin-top: 1.1em;
		margin-bottom: .8em;
		line-height: var(--lh-title);
	}

	h1, .h1 { font-size: $fs-h1; }

	h2, .h2 {
		font-size: $fs-h2;
		margin-top: calc(var(--lh-title) * 1.15);
		margin-bottom: var(--lh-title);
	}

	h3, .h3 {
		font-size: $fs-h3;
		margin-top: calc(var(--lh-title) * 1.5);
		margin-bottom: calc(var(--lh-title) * 1.25);
	}

	.text--area {
		> *:first-child {
			margin-top: 0;
		}

		> *:last-child {
			margin-bottom: 0;
		}
	}

	.bg--green { background-color: $cl-green; }
	.bg--green-deep { background-color: $cl-green-deep; }
	.bg--sand { background-color: $cl-sand; }
	.bg--sand-deep { background-color: $cl-sand-deep; }
	.bg--orange { background-color: $cl-orange; }
	.bg--blue { background-color: $cl-blue; }
	.bg--white { background-color: $cl-white; }

	.cl--green { color: $cl-green; }
	.cl--green-deep { color: $cl-green-deep; }
	.cl--sand { color: $cl-sand; }
	.cl--sand-deep { color: $cl-sand-deep; }
	.cl--orange { color: $cl-orange; }
	.cl--blue { color: $cl-blue; }
	.cl--white { color: $cl-white; }

	.container--1 {
		max-width: 1920px;
		margin: 0 auto;
		width: 100%;
		padding-left: var(--gutter-container);
		padding-right: var(--gutter-container);
	}

	.offset--start_1 {
		padding-left: var(--gutter-container);
	}

	.offset--end_1 {
		padding-right: var(--gutter-container);
	}

	.row--1 {
		margin-left: calc(0px - (var(--gutter-container) / 2));
		margin-right: calc(0px - (var(--gutter-container) / 2));

		> * {
			padding-left: calc(var(--gutter-container) / 2);
			padding-right: calc(var(--gutter-container) / 2);
		}
	}