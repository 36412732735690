.privacy--item {
	& + .privacy--item {
		border-top: 2px solid $cl-white;
	}

	&:first-child {
		@media (max-width : 991px) {
			border-top: 2px solid $cl-white;
		}
	}

	p:last-child {
		margin-bottom: 0;
	}

	.h3 {
		@media (min-width : 992px) {
			position: sticky;
			top: calc(var(--gutter-container) / 1.75);
		}

		@media (max-width : 991px) {
			font-size: $fs-h2;
		}
	}
}