.process--env {
	.mr--i_full.is--revealed {
		opacity: .5 !important;
	}
}

.process--list {
	nav {
		@media (min-width : 992px) {
			position: sticky;
			top: calc(var(--gutter-container) / 1.75);
		}

		button {
			display: flex;
			align-items: center;
			color: $cl-sand;

			small {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: var(--lh-content);
				width: var(--gutter-container);
				height: var(--gutter-container);
				border-radius: 50%;
				font-size: 1rem;

				@media (min-width : 992px) {	
					width: clamp(50px, var(--gutter-container), calc((100vh - ((var(--gutter-container) / 1.75) * 2)) / 5));
					height: clamp(50px, var(--gutter-container), calc((100vh - ((var(--gutter-container) / 1.75) * 2)) / 5));
				}

				&:before {
					content: '';
					display: block;
					position: absolute;
					inset: 0;
					border-radius: 20%;
					border: var(--bw-base) solid $cl-white;
					transform: rotate(45deg) scale(.7);
					transition: all var(--time-slow) ease;
				}
			}

			@media (hover : hover) {
				&:hover {
					color: $cl-green;
				}
			}

			&.is--active {
				color: $cl-green;

				small:before {
					border-radius: 50%;
					border-color: $cl-sand;
					transform: rotate(0deg) scale(1);
				}
			}
		}
	}
}

.process--list_item {
	position: relative;

	h3 {
		small {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			margin-right: .5em;
			border: var(--bw-base) solid $cl-sand;
			font-size: 80%;
		}
	}

	h2,
	.text--area {
		@media (min-width : 768px) {
			padding-left: calc(var(--gutter-container) * 1.5);
			padding-right: calc(var(--gutter-container) * 1.5);
		}

		@media (min-width : 992px) {
			padding-left: 0;
			padding-right: var(--gutter-container);
		}
	}

	.text--area {
		margin-top: var(--lh-content);
	}

	.anchor {
		display: block;
		width: 1px;
		height: 1px;
		position: absolute;
		top: calc(0px - (var(--gutter-container) / 1.75));
	}

	.mr--i {
		@media (max-width : 575px) {
			&:not(.h-100) {
				margin-right: 1px;
			}

			&.h-100 {
				margin-left: 1px;
			}
		}
	}
}