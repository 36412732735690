.about--title {
	.mr--i:before {
		@media (max-width : 1199px) and (min-width : 992px) {
			padding-bottom: 82%;
		}
	}

	.offset--end_1 {
		@media (max-width : 991px) {
			padding-right: 0 !important;
		}
	}
}

.about--text {
	.mr--i:before {
		@media (max-width : 1199px) and (min-width : 992px) {
			padding-bottom: 75%;
		}
	}

	.offset--end_1 {
		@media (max-width : 991px) {
			padding-right: 0 !important;
		}
	}
}

.about--env {
	.mr--i_full {
		mix-blend-mode: luminosity;
		opacity: .15;
	}
}

.about--numbers {
	@media (min-width : 576px) {
		white-space: nowrap;
	}

	.h1 {
		letter-spacing: -0.04em;

		@media (max-width : 1199px) and (min-width : 992px) {
			font-size: calc(var(--fs-h1) * 0.75);
		}

		@media (max-width : 575px) {
			font-size: calc(var(--fs-h1) * 1.25);
		}

		small {
			font-size: 1rem;
			letter-spacing: 0;
		}
	}

	p {
		@media (max-width : 991px) and (min-width : 576px) {
			flex: 0 0 33%;

			&:nth-last-child(1)
			&:nth-last-child(2), {
				flex: 0 0 50%;
			}
		}

		@media (max-width : 575px) {
			flex: 0 0 100%;
		}
	}

	[ef-counter] {
		position: relative;
		display: block;
		line-height: 1;
		margin-bottom: .2em;

		span {
			display: block;
			line-height: 1;
			
			&:after {
				content: attr(toneladas);
				font-size: 1rem;
				margin-left: 5px;
				letter-spacing: 0;
			}

			&:first-child {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}

			&:last-child {
				opacity: 0;
			}
		}
	}
}

.about--brand {
	@media (max-width : 991px) {
		padding-left: 0;
		padding-right: 0;
	}

	& + .about--brand {
		border-top: var(--bw-base) solid $cl-sand;
	}

	&:nth-child(even) {
		@media (min-width : 768px) {
			.row {
				flex-direction: row-reverse;
			}
			
			.about--brand_image {
				padding-left: 0;
				padding-right: calc(var(--gutter-container) * 2);
			}
		}
	}

	.row {
		@media (max-width : 767px) {
			flex-direction: column-reverse;
		}
	}

	a {
		@media (hover : hover) {
			&:hover {
				color: $cl-sand-deep;
			}
		}
	}
}

.about--brand_image {
	@media (min-width : 768px) {
		padding-left: calc(var(--gutter-container) * 2);
	}

	@media (max-width : 767px) {
		padding: 0 20%;
	}
}

.about--work {
	.mr--i_full.is--revealed {
		opacity: .4 !important;
	}
}