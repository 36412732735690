.offset--header {
	padding-top: var(--offset-header);
}

.header {
	--header-color: #{$cl-sand};
	--header-contrast: #{$cl-sand-deep};
	--header-backdrop: #{$cl-green};
	padding: var(--gutter-y-sm) 0 0;
	z-index: 19;

	&.header_invert {
		--header-color: #{$cl-green};
		--header-contrast: #{$cl-sand};
		--header-backdrop: #{$cl-white};

		@media (max-width : 991px) {
			.header--pages {
				@supports (backdrop-filter: blur(10px)) {
					background: rgba(255,255,255,0.5);
				}
			}
		}
	}
}

.header--brand {
	display: block;
	color: var(--header-color) !important;
	flex: 0 0 200px;
	flex: 0 0 clamp(200px, 15.6vw, 300px);

	@media (max-width : 1199px) {
		flex: 0 0 190px;
		flex: 0 0 clamp(190px, 13vw, 250px);
	}

	@media (max-width : 991px) {
		flex: 0 0 215px;
	}

	@media (max-width : 767px) {
		flex: 0 0 190px;
	}

	@media (max-width : 575px) {
		flex: 0 0 160px;
	}
}

.header--nav-toggle {
	position: relative;
	z-index: 1;
	padding: .5rem 1rem;
	margin: 0 -1rem 0 1rem;
	transition: transform var(--time-slow) ease;

	@media (max-width : 575px) {
		margin-left: 0;
		padding: .75rem 1rem;
	}

	i {
		display: block;
		width: 2rem;
		height: 2px;
		background: var(--header-color);
		margin: auto;
		transition: transform var(--time-slow) ease, opacity var(--time-normal) ease;

		&:nth-child(2) {
			margin: .5rem auto;
		}
	}
}

.header--pages {
	@media (max-width : 991px) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: calc(var(--offset-header) + var(--gutter-y-sm)) var(--gutter-container) var(--gutter-y-sm);
		background: rgba(0,0,0,0.25);
		backdrop-filter: blur(10px);
		transform: translate3d(0,-101%,0);
		transition: transform var(--time-slow) ease var(--time-delay);

		@supports not (backdrop-filter: blur(10px)) {
			background: var(--header-backdrop);
		}

		> li {
			opacity: 0;
			transition: opacity var(--time-slow) ease;
		}
	}

	li {
		margin-right: 1.8em;

		@media (max-width : 1199px) {
			margin-right: 1.25em;
		}

		@media (max-width : 1199px) {
			margin-right: 1.25em;
		}

		@media (max-width : 767px) and (min-width : 576px) {
			flex: 0 0 33.3333%;
		}

		@media (max-width : 767px) {
			margin-right: 0;
		}

		a {
			display: block;
			padding: .75em 0;
			line-height: 1;
			color: var(--header-color);

			@media (max-width : 767px) {
				padding: 1em 0;
			}

			@media (hover : hover) {
				&:hover {
					color: $cl-sand-deep;
				}
			}
		}
	}
}

.header--lang {
	padding: .8em 0;

	> button {
		padding: .3em .6em .3em .5em;
		border-radius: var(--br-base);
		color: $cl-sand;
		background: $cl-sand-deep;

		&:before {
			content: '';
			display: block;
			position: absolute;
			inset: 0
		}

		.icon:first-child {
			margin-right: .4em;
		}

		.icon:nth-child(2) {
			transition: transform var(--time-slow) ease;
		}

		&.show {
			background: $cl-green;

			.icon:nth-child(2) {
				transform: rotate(180deg);
			}
		}
	}

	> ul {
		position: absolute;
		top: 100%;
		right: 0;
		border-radius: var(--br-base);
		border: var(--bw-base) solid var(--header-color);
		overflow: hidden;
		height: 0;
		opacity: 0;
		pointer-events: none;
		transition: opacity var(--time-normal) ease var(--time-delay), height var(--time-slow) ease;

		@media (max-width : 991px) {
			background: var(--header-color);
		}

		&.show {
			pointer-events: all;
			opacity: 1;
			height: calc(4px + 0.5rem + ((var(--fs-medium) + var(--fs-lead)) * var(--languages-count)));
			transition: opacity var(--time-normal) ease, height var(--time-slow) ease;

			@media (max-width : 767px) {
				height: calc(4px + 0.8rem + ((1.2rem + var(--fs-lead)) * var(--languages-count)));
			}
		}
		
		&:before,
		&:after {
			content: '';
			display: block;
			height: .25rem;

			@media (max-width : 767px) {
				height: .4rem;
			}
		}
		
		a {
			position: relative;
			display: flex;
			align-items: center;
			font-size: var(--fs-medium);
			color: var(--header-color);
			padding: .5em 1.25em .5em 1.5em;
			line-height: 1em;

			@media (max-width : 991px) {
				color: var(--header-contrast);
			}

			@media (max-width : 767px) {
				font-size: 1rem;
				padding: .6em 1.25em .6em 1.5em;
			}

			@media (hover : hover) {
				&:not(.is--active):hover {
					opacity: .5;
				}
			}

			img {
				width: var(--fs-lead);
				flex: 0 0 var(--fs-lead);
				margin-right: .5em;
				display: block;
				border-radius: 50%;
			}

			&.is--active {
				cursor: default;

				&:before {
					content: '';
					display: block;
					border-radius: 50%;
					width: 8px;
					height: 8px;
					background: var(--header-color);
					position: absolute;
					left: .6em;
					top: 50%;
					margin-top: -4px;

					@media (max-width : 991px) {
						background: var(--header-contrast);
					}
				}
			}
		}
	}

	@media (hover : hover) {
		&:hover {
			> button {
				background: $cl-green;

				.icon:nth-child(2) {
					transform: rotate(180deg);
				}
			}

			> ul {
				pointer-events: all;
				opacity: 1;
				height: calc(4px + 0.5rem + ((var(--fs-medium) + var(--fs-lead)) * var(--languages-count)));
				transition: opacity 250ms ease, height var(--time-slow) ease;

				@media (max-width : 767px) {
					height: calc(4px + 0.8rem + ((1.2rem + var(--fs-lead)) * var(--languages-count)));
				}
			}
		}
	}
}

.is--nav {
	@media (max-width : 991px) {
		.header--pages {
			transform: translate3d(0,0,0);
			transition: transform var(--time-slow) ease;

			> li {
				opacity: 1;
				transition: opacity var(--time-slow) ease var(--time-delay);
			}
		}
	}

	.header--nav-toggle {
		transform: rotate(-45deg);

		i {
			&:nth-child(1) {
				transform: translateY(calc(2px + 0.5rem));
			}

			&:nth-child(2) {
				transform: (rotate(-90deg));
			}

			&:nth-child(3) {
				opacity: 0;
				transform: translateY(calc(0px - (2px + 0.5rem)));
			}
		}
	}
}